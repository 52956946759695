<template>
    <div class="footer-box" :style="{backgroundImage: 'url('+require('@/assets/images/footer/footer.png')+')'}">
        <div class="container">
            <div class="footer-text">
                <p>平台仍处在内测阶段，我们将竭诚进行完善</p>
                <p>该界面不代表平台最终版本，如造成不便，我们深感抱歉</p>
                <p class="footer-text-feedback">反馈及咨询通道：0756-8929812</p>
            </div>
            <div class="footer-wechat">
                <img :src="require('@/assets/images/wechat02.png')" alt="">
                <span class="footer-wechat-text">
                    <span>扫码关注</span>
                    <span>微信公众号</span>
                </span>
            </div>
            <div class="footer-other">
                <router-link to="/about">关于我们</router-link>
                <router-link to="/contact">联系我们</router-link>
                <router-link to="/join">加入我们</router-link>
                <router-link to="/history">发展历程</router-link>
            </div>
            <div class="footer-copy">
                &copy;2019 ijustsharing.com 版权所有 珠海吉享空间网络科技有限公司
                <a href="http://www.beian.miit.gov.cn" target="_blank">粤ICP备19016805号-1</a>
                <a href="javascript:;" 
                    class="footer-emblem"
                    :style="{backgroundImage:'url('+require('@/assets/images/footer/emblem.png')+')'}">
                    粤公网安备 44040202001013
                </a>
            </div> 
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.footer-box{
    position: relative;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.footer-box>.container{
    padding: 0 120px;
}
/* 文字部分 */
.footer-text{
    margin-top: 60px;
}
.footer-text>p{
    font-size: 20px;
    color: #5b64c9;
}
.footer-text>.footer-text-feedback{
    margin-top: 40px;
    font-size: 25px;
}
/* 公众号二维码 */
.footer-wechat{
    margin-top: 90px;
    padding: 0 85px;
}
.footer-wechat>img{
    width: 100px;
    height: 100px;
}
.footer-wechat-text{
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
}
.footer-wechat-text>span{
    display: block;
    color: #5b64c9;
    font-size: 16px;
}
.footer-other{
    position: absolute;
    bottom: 50px;
}
.footer-other>a+a{
    margin-left: 50px;
}
/* 版权 */
.footer-copy{
    position: absolute;
    bottom: 20px;
    color: #5b64c9;
}
.footer-copy>a{
    color: #8d9afc;
    text-decoration: underline !important;
}
.footer-emblem{
    margin-left: 10px;
    padding-left: 20px;
    background-repeat: no-repeat;
    background-size: 20px 20px;
}
/* 媒体查询 */
@media (min-width: 1900px) { 
    .footer-box{
        height:800px;
    }
    /* 微信 */
    .footer-wechat{
        margin-top: 200px;
    }
    .footer-wechat>img{
        width: 150px;
        height: 150px;
    }
}
@media (min-width: 1600px) and (max-width: 1899px) { 
    .footer-box{
        height:700px;
    }
    /* 微信 */
    .footer-wechat{
        margin-top: 130px;
    }
    .footer-wechat>img{
        width: 130px;
        height: 130px;
    }
}
@media (min-width: 1200px) and (max-width: 1599px) { 
    .footer-box{
        height: 600px;
    }
}
@media (min-width: 992px) and (max-width: 1199px) { 
    .footer-box{
        height: 450px;
    }
    /* 微信 */
    .footer-wechat{
        margin-top: 50px;
    }
    .footer-wechat>img{
        width: 70px;
        height: 70px;
    }
}
@media (max-width: 991px) { 
    .footer-box{
        height: 450px;
    }
    .footer-box>.container{
        padding: 0 40px;
    }
    /* 微信 */
    .footer-wechat{
        margin-top: 40px;
    }
    .footer-wechat>img{
        width: 70px;
        height: 70px;
    }
}
</style>